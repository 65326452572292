import { createGlobalStyle } from '@xstyled/styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    color: grey;
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    line-height: 1.4;
    -moz-font-feature-settings: "case";
    -webkit-font-feature-settings: "case";
    font-feature-settings: "case";
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: stretch;
  }
  .scrollingDisabled {
    overflow: hidden;
  }
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: stretch;
  }
  main {
    min-height: 50vh;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: "PT Serif", serif;
    font-weight: 700;
    line-height: 1.4;
  }
  h1 {
    font-size: calc((28 / 16) * 1rem);
    @media screen and (max-width: 1023px) {
      font-size: calc((26 / 16) * 1rem);
    }
  }
  h2 {
    font-size: calc((28 / 16) * 1rem);
    @media screen and (max-width: 1023px) {
      font-size: calc((26 / 16) * 1rem);
    }
  }
  h3 {
    font-size: calc((26 / 16) * 1rem);
  }
  h4 {
    font-size: calc((22 / 16) * 1rem);
  }
  h5 {
    font-size: calc((19 / 16) * 1rem);
  }
  h6 {
    font-size: calc((16 / 16) * 1rem);
  }
  a {
    color: grey;
    text-decoration: none;
    transition: all linear .3s;
    * {
      transition: all linear .3s;
    }
    &:hover {
      * {
        color: #000;
      }
    }
  }
  .trendsbar a {
    &:hover {
      span {
        color: orange;
      }
    }
  }
  button {
    transition: all linear .3s;
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  strong, b {
    font-weight: semiBold;
  }
  em, i {
    font-style: italic;
  }
  small {
    font-size: small;
  }
  .active a {
    position: relative;
    color: red;
  }
  .active-sub-nav span {
    &::before {
      background-color: entertainment;
      transform-origin: bottom left;
      transform: scaleX(1);
    }
  }
  .article-share, .author-follow {
    a, button {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      position: relative;
      color: white;
      background-color: #EEE;
      overflow: hidden;
      transition: color 0.4s ease-in-out;
      border: 1px solid #EEE;
      border-radius: 100%;
      width: 40px;
      height: 40px;
      @media screen and (max-width: 1023px) {
        width: 36px;
        height: 36px;
      }
      &::before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        background-color: #FFF;
        transform-origin: center;
        transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
        transition: transform 0.45s ease-in-out;
      }
      &:hover::before {
        transform: translate3d(-50%, -50%, 0) scale3d(15, 15, 15);
      }
    }
  }
  .article-share {
    a, button {
      padding: 0;
    }
  }
  .author-follow {
    a, button {
      width: 30px;
      height: 30px;
    }
  }
  @media screen and (min-width: 1024px) {
    .divider-right {
      &::after {
        content: '';
        border-right: 1px solid #D4D4D4;
        position: absolute;
        top: 0;
        right: -20px;
        height: 100%;
      }
      &:nth-of-type(4n)::after {
        border-right: 0;
      }
    }
    .divider-left {
      &::before {
        content: '';
        border-left: 1px solid #D4D4D4;
        position: absolute;
        top: 0;
        left: -20px;
        height: 100%;
      }
      &:nth-of-type(4n)::before {
        border-left: 0;
      }
    }
    .divider-right-six {
      &::after {
        content: '';
        border-right: 1px solid #D4D4D4;
        position: absolute;
        top: 0;
        right: -20px;
        height: 100%;
      }
    }
    #carousel li {
      &:last-of-type, &:nth-of-type(4) {
        &.divider-right::after {
          border-right: 0;
        }
      }
      &.divider-right::after {
        right: 0;
      }
    }
    .divider-right-footer::after {
      content: '';
      border-right: 1px solid rgba(255, 255, 255, .3);
      position: absolute;
      top: 0;
      right: -20px;
      height: 100%;
    }
  }
  .iframeWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
  }
  .video-frame {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    iframe, video {
      width: 100%;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #999;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #999;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #999;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #999;
  }
  .static-pages {
    color: grey;
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    line-height: 1.4;
    -moz-font-feature-settings: "case";
    -webkit-font-feature-settings: "case";
    font-feature-settings: "case";
    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 20px;
  }
    p {
      margin-bottom: 15px;
    }
    a {
      color: #2F80ED;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    ul {
      margin-left: 40px;
      margin-bottom: 20px;
    }
    li {
      list-style-type: disc;
    }
    table {
      border-collapse: collapse;
      th {
        border-bottom: 1px solid #D4D4D4;
        text-align: left;
        text-transform: uppercase;
      }
      th, td {
        padding: 5px;
        width: 50%;
      }
    }
  }
  .newspapers {
    max-width: 970px;
    width: 100%;
    margin: auto;
    color: grey;
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    line-height: 1.4;
    -moz-font-feature-settings: "case";
    -webkit-font-feature-settings: "case";
    font-feature-settings: "case";
    a {
      color: #2F80ED;
      &:hover {
        text-decoration: none;
      }
    }
    img {
      border: 1px solid #DDD;
      margin-bottom: 5px;
    }
  }
  .newspaper-titles {
    display: grid;
    grid-template-columns: repeat(4, calc(100% / 4));
    @media screen and (max-width: 1022px) {
      grid-template-columns: repeat(3, calc(100% / 3));
    }
    a {
      display: block;
    }
    img {
      display: block;
      margin: auto;
    }
  }
  .scroll-to-top {
    animation: fadeIn 700ms ease-in-out 1s both;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  // Video
  .oovvuu-embed-player .ov-bg-gray-100 {
    background-color: #FFF;
  }
  .oovvuu-embed-player .ov-bg-teal {
    background-color: #B41400;
  }
  .oovvuu-embed-player .ov-text-teal {
    color: #B41400;
  }
  .video-page {
    .oovvuu-embed-player .ov-text-gray-800 {
      display: none;
      visibility: hidden;
    }
  }
  .video-page .video-box {
    .oovvuu-embed-player .ov-text-gray-800 {
      display: flex;
      visibility: visible;
    }
  }
  .video {
    + div:empty {
      margin-bottom: 10px;
    }
  }
  .video-youtube iframe {
    aspect-ratio: 16 / 9;
    width: 100%;
    height: auto;
  }
  main {
    position: relative;
    z-index: 2;
    > div {
      position: relative;
      z-index: 2;
    }
  }
  // Wallpaper ad
  .dfp-take-over-ad {
      .wallpaper-ad {
          position: fixed;
          width: 100%;
          height: 100%;
          z-index: 1;
          top: 172px;
          &.scrolled {
              top: 72px;
          }
          div,
          iframe {
              position: fixed;
              width: 100%;
              height: 100%;
              z-index: 1;
          }
      }
      header, main {
          position: relative;
          z-index: 101;
          background: #FFF;
      }
      main {
        padding: 20px;
        z-index: 100;
        margin: auto;
      }
      footer {
          position: relative;
          z-index: 100;
      }
  }
  .weather-list {
    &:nth-of-type(2n+1) {
      @media screen and (max-width: 1023px) {
        border-right: 0 none;
        grid-column: 2 / 2;
      }
    }
  }
  // Hide Empty Ads
  .ad-unit:has(div[id^='google_ads_iframe']:empty) {
    visibility: hidden;
    height: 0;
    padding: 0;
  }
  .checkboxes {
    label {
      display: block; // to make it easier to click
      cursor: pointer;
      position: relative; // important
      width: 100%;
      max-width: 350px;
      min-width: 300px;
      padding: 5px;
      color: #434343;
      // Now we'll create the checkbox object
      span {
        display: inline-block;
        position: relative;
        background: #F0F0F0;
        width: 25px;
        height: 25px;
        transform-origin: center;
        vertical-align: middle;
        margin-right: 10px;
        transition: background-color 150ms 200ms, transform 350ms cubic-bezier(.78,-1.22,.17,1.89); // custom ease effect for bouncy animation
        // Now we'll create the "tick" using pseudo elements - those will be basically two lines that will be rotated to form the "tick"
        &:before {
          content: "";
          width: 0px;
          height: 2px;
          border-radius: 2px; // so that the tick has nice rounded look
          position: absolute;
          transform: rotate(45deg);
          top: 12px; // you'll need to experiment with placement depending on the dimensions you've chosen
          left: 9px; // you'll need to experiment with placement depending on the dimensions you've chosen
          transition: width 50ms ease 50ms;
          transform-origin: 0% 0%;
        }
        &:after {
          content: "";
          width: 0;
          height: 2px;
          border-radius: 2px; // so that the tick has nice rounded look
          position: absolute;
          transform: rotate(305deg);
          top: 15px; // you'll need to experiment with placement depending on the dimensions you've chosen
          left: 10px; // you'll need to experiment with placement depending on the dimensions you've chosen
          transition: width 50ms ease;
          transform-origin: 0% 0%;
        }
      }
      // Time to add some life to it
      &:hover {
          transition: background linear .2s;
          color: #B41400;
          span {
              &:before {
                  width: 5px;
                  transition: width 100ms ease;
              }
              &:after {
                  width: 10px;
                  transition: width 150ms ease 100ms;
              }
          }
      }
    }
    input[type="checkbox"] {
      display: none; // hide the system checkbox
      // Let's add some effects after the checkbox is checked
      &:checked {
        + label {
          color: #434343;
          span {
            background: #F0F0F0;
            // transform: scale(1.25); // enlarge the box
            &:after {
              width: 10px;
              background: #434343;
              transition: width 150ms ease 100ms; // enlarge the tick
            }
            &:before {
              width: 5px;
              background: #434343;
              transition: width 150ms ease 100ms; // enlarge the tick
            }
          }
          &:hover { // copy the states for onMouseOver to avoid flickering
            color: #B41400;
            span {
              background: #F0F0F0;
              // transform: scale(1.25); // enlarge the box
              &:after {
                width: 10px;
                background: #434343;
                transition: width 150ms ease 100ms; // enlarge the tick
              }
              &:before {
                width: 5px;
                background: #434343;
                transition: width 150ms ease 100ms; // enlarge the tick
              }
            }
          }
        }
      }
    }
  }
  .letters {
    a {
      word-break: break-word;
    }
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 20px;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #C4C4C4;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
    border: 1px solid transparent;
    &::after {
      content: "OFF";
      font-size: 10px;
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translatey(-50%);
    }
    &::before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 1px;
      top: 1px;
      background-color: white;
      transition: .4s;
      border-radius: 50%;
    }
  }
  input:checked + .slider {
    background-color: #15CF74;
  }
  input:focus + .slider {
    border: 1px solid #000;
  }
  input:checked + .slider {
    &::after {
      content: "ON";
      left: 9px;
      color: #3E6C56;
    }
    &::before {
      transform: translateX(26px);
    }
  }
  .ovx-v2-strap-embed .ovx-playing-next > span {
    display: none;
  }
  .oovvuu-embed-player .ov-fixed {
    bottom: 130px !important;
  }
  .opinion-secondary-nav {
    font-family: "Museo Sans", sans-serif;
  }
`

export default GlobalStyle
