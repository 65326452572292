import { PAGE_VIEW, HOME_PAGE_VIEW, SECTION_PAGE_VIEW, STATIC_PAGE_VIEW, EXPERIMENT_WIN, AD_BLOCKER, ERROR_PAGE_VIEW } from './app'
import { FETCH_ARTICLE_SUCCESS, OOVVUU_ARTICLE_RENDER } from './article'

const sanitizeUserId = (userId) => encodeURIComponent(userId).replace(/[%/=]+/g, '')

// Map the event to a Redux action
export const eventsMap = {
  [SECTION_PAGE_VIEW]: (action) => ({
    event: 'SectionPageview',
    firstRender: action.firstRender,
    virtualPageURL: action.payload.path,
    section: action.payload.section,
    visitorId: sanitizeUserId(action.visitorId),
    sessionId: action.sessionId,
    titleKey: process.env.RAZZLE_TITLE_KEY
  }),
  [STATIC_PAGE_VIEW]: (action) => ({
    event: 'StaticPageview',
    firstRender: action.firstRender,
    virtualPageURL: action.payload,
    visitorId: sanitizeUserId(action.visitorId),
    sessionId: action.sessionId,
    titleKey: process.env.RAZZLE_TITLE_KEY
  }),
  [HOME_PAGE_VIEW]: (action) => ({
    event: 'HomePageview',
    firstRender: action.firstRender,
    virtualPageURL: action.payload,
    visitorId: sanitizeUserId(action.visitorId),
    sessionId: action.sessionId,
    titleKey: process.env.RAZZLE_TITLE_KEY
  }),
  [PAGE_VIEW]: (action) => ({
    event: 'VirtualPageview',
    virtualPageURL: action.payload,
    visitorId: sanitizeUserId(action.visitorId),
    sessionId: action.sessionId
  }),
  [OOVVUU_ARTICLE_RENDER]: (action) => ({
    event: 'OovvuuView',
    section: action.payload.split('/')[0]
  }),
  [FETCH_ARTICLE_SUCCESS]: (action) => ({
    event: 'ArticlePageview',
    contentKey: action.payload.contentKey,
    section: action.payload.section,
    author: action.payload.author,
    visitorId: sanitizeUserId(action.payload.visitorId),
    sessionId: action.payload.sessionId,
    title: action.payload.headline,
    host: process.env.RAZZLE_SITE_URL,
    path: action.path,
    titleKey: action.payload.titleKey
  }),
  [EXPERIMENT_WIN]: (action) => ({
    event: 'ABTest',
    experiment: action.payload,
    variant: action.variant
  }),
  [ERROR_PAGE_VIEW]: (action) => ({
    event: 'ErrorPageView',
    virtualPageURL: action.payload,
    visitorId: sanitizeUserId(action.visitorId)
  }),
  [AD_BLOCKER]: (action) => ({
    event: 'AdBlockerDetected',
    virtualPageURL: action.payload,
    host: process.env.RAZZLE_SITE_URL
  })
}
