import React from 'react'
import styled, { x, useDown } from '@xstyled/styled-components'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import StyledLink from './../components/ui/Link'
import NewsletterSubscribePopup from '../components/NewsletterSubscribePopup'
import { getSessionId, getVisitorId } from '../../store/article'

const StyledNavBar = styled.nav`
  width: 100%;
  max-width: 1220px;
  > ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    text-transform: uppercase;
    ul {
      display: none;
      visibility: hidden;
      opacity: 0;
      background: white;
      z-index: 1000;
      top: 100%;
      left: 0;
      position: absolute;
      margin: 0;
      box-shadow: 0 0 8px rgba(0,0,0,.3);
      transition: all linear 2s;
      min-width: 233px;
    }
    > li {
      position: relative;
      &[aria-haspopup='true'] {
        > a {
          padding-right: 19px;
          display: inline-block;
          &::after {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            right: 4px;
            top: 50%;
            transform: translateY(-50%);
            background-image: url('/images/arrow-dropdown.svg');
            background-position: right center;
            background-repeat: no-repeat;
            background-size: 8px;
            display: block;
            transition: all linear .3s;
          }
          &:hover::after {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
      &:last-of-type > a {
        color: #FFF;
        &:hover {
          color: #FFF;
        }
      }
      > ul {
        margin-top: 8px;
        &::before {
          content: '';
          position: absolute;
          bottom: 100%;
          left: 24px;
          z-index: 1001;
          border-right: 5px solid transparent;
          border-bottom: 5px solid #FFF;
          border-left: 5px solid transparent;
        }
        &::after {
          content: '';
          position: absolute;
          bottom: 100%;
          left: 0;
          width: 100%;
          height: 8px;
          background: transparent;
        }
        > li {
          position: relative;
          &[aria-haspopup='true'] {
            > a {
              padding-right: 20px;
              display: block;
              &::after {
                content: '';
                position: absolute;
                width: 8px;
                height: 8px;
                right: 15px;
                top: 50%;
                transform: translateY(-50%) rotate(270deg);
                background-image: url('/images/arrow-dropdown.svg');
                background-position: right center;
                background-repeat: no-repeat;
                background-size: 8px;
                display: block;
                transition: all linear .3s;
              }
            }
          }
          &:hover {
            > ul {
              display: block;
              visibility: visible;
              opacity: 1;
              transition: all .5s linear 2s;
              border-top: 1px solid #EEE;
              left: 100%;
              top: -1px;
              margin-left: 8px;
              &::before {
                content: '';
                position: absolute;
                top: 18px;
                right: 100%;
                bottom: auto;
                left: auto;
                z-index: 1001;
                border-top: 5px solid transparent;
                border-right: 5px solid #FFF;
                border-bottom: 5px solid transparent;
              }
              &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 100%;
                bottom: auto;
                left: auto;
                width: 8px;
                height: 100%;
                background: transparent;
              }
            }
            &[data-left='true']:hover > ul {
              right: 100%;
              left: auto;
              top: -1px;
              margin-left: 0;
              margin-right: 8px;
              &::before {
                right: auto;
                left: 100%;
                border-top: 5px solid transparent;
                border-right: 5px solid transparent;
                border-left: 5px solid #FFF;
                border-bottom: 5px solid transparent;
              }
              &::after {
                right: -100%;
              }
            }
          }
        }
      }
    }
    > li {
      align-self: center;
      &:hover {
        > ul {
          display: block;
          visibility: visible;
          opacity: 1;
          transition: all .5s linear 0;
          a {
            padding: 10px 15px;
            display: block;
            text-align: left;
            position: relative;
            z-index: 1000;
            border-bottom: 1px solid #EEE;
            &:hover {
              color: #474747;
              background: #F0F0F0;
            }
          }
        }
      }
    }
  }
  a {
    padding: 10px;
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    &:hover {
      color: #B41400;
    }
  }
  > ul > li:hover {
    > a {
      color: #B41400;
      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
`
const NavBar = (props) => {
  const isMobile = useDown('md')
  const clickSource = 'Nav Bar'
  return (
    <x.div w='100%' borderColor='grey' borderTopWidth='1' borderBottomWidth='1' visibility={{ sm: 'hidden', lg: 'visible' }} display={{ sm: 'none', lg: 'flex' }} justifyContent='center' px={20} position='relative'>
      <x.div w='100%' maxWidth='1220px' display='flex' alignItems='center' justifyContent='center' fontSize='medium'>
        <StyledNavBar>
          <ul>
            <li aria-haspopup='true'><StyledLink to='/news' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>News</StyledLink>
              <ul>
                <li aria-haspopup='true'>
                  <StyledLink to='/news/south-africa' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>South Africa</StyledLink>
                  <ul>
                    <li>
                      <StyledLink to='/news/south-africa/eastern-cape' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Eastern Cape</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/news/south-africa/free-state' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Free State</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/news/south-africa/gauteng' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Gauteng</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/news/south-africa/kwazulu-natal' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>KwaZulu-Natal</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/news/south-africa/limpopo' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Limpopo</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/news/south-africa/mpumalanga' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Mpumalanga</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/news/south-africa/northern-cape' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Northern Cape</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/news/south-africa/north-west' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>North West</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/news/south-africa/western-cape' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Western Cape</StyledLink>
                    </li>
                  </ul>
                </li>
                <li><StyledLink to='/news/politics' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Politics</StyledLink></li>
                <li>
                  <StyledLink to='/news/crime-and-courts' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Crime and Courts</StyledLink>
                </li>
                <li>
                  <StyledLink to='/news/world' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>World</StyledLink>
                </li>
                <li>
                  <StyledLink to='/news/africa' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Africa</StyledLink>
                </li>
                <li>
                  <StyledLink to='/business-report/brics' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>BRICS</StyledLink>
                </li>
                <li aria-haspopup='true'><StyledLink to='/news/education' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Education</StyledLink>
                  <ul>
                    <li>
                      <StyledLink to='/news/education/early-learning' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Early Learning</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/news/education/matric' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Matric</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/news/education/universities' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Universities</StyledLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <StyledLink to='/news/environment' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Environment</StyledLink>
                </li>
                <li>
                  <StyledLink to='/news/weather' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Weather</StyledLink>
                </li>
                <li>
                  <StyledLink to='/news/feel-good' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Good News</StyledLink>
                </li>
                <li>
                  <StyledLink to='/news/energy' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Energy</StyledLink>
                </li>
              </ul>
            </li>
            <li aria-haspopup='false'><StyledLink to='/opinion' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Opinion</StyledLink>
            </li>
            <li aria-haspopup='true'><StyledLink to='/business' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Business</StyledLink>
              <ul>
                <li aria-haspopup='true'><StyledLink to='/business-report' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Business Report</StyledLink>
                  <ul>
                    <li>
                      <StyledLink to='/business-report/economy' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Economy</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/business-report/markets' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Markets</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/business-report/international' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>International</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/business-report/budget' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Budget</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/business-report/opinion' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Opinion</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/business-report/entrepreneurs' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Entrepreneurs</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/business-report/brics' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>BRICS</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/business-report/ending-poverty-in-china' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Window on China</StyledLink>
                    </li>
                  </ul>
                </li>
                <li aria-haspopup='true'><StyledLink to='/personal-finance' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Personal Finance</StyledLink>
                  <ul>
                    <li aria-haspopup='true'>
                      <StyledLink to='/personal-finance/my-money' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>My Money</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/personal-finance/debt' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Debt</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/personal-finance/financial-planning' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Financial Planning</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/personal-finance/retirement' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Retirement</StyledLink>
                    </li>
                    <li aria-haspopup='true'>
                      <StyledLink to='/personal-finance/investments' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Invest</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/personal-finance/insurance' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Insurance</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/personal-finance/medical-cover' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>medical cover</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/personal-finance/tax' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>tax</StyledLink>
                    </li>
                  </ul>
                </li>
                <li aria-haspopup='true'><StyledLink to='/property' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Property</StyledLink>
                  <ul>
                    <li>
                      <StyledLink to='/property/commercial' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Commercial</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/property/residential' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Residential</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/lifestyle/home-garden' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Home &amp; Garden</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/lifestyle/green-living' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Simply Green</StyledLink>
                    </li>
                  </ul>
                </li>
                <li aria-haspopup='true'><StyledLink to='/technology' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Technology</StyledLink>
                  <ul>
                    <li>
                      <StyledLink to='/technology/mobile-apps' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Mobile Apps</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/technology/gadgets' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Gadgets</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/technology/fintech' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Fintech</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/technology/gaming' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Gaming</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/technology/big-tech' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Big Tech</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/technology/e-sports' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>e-Sports</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/technology/shopping' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Shopping</StyledLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <StyledLink to='/business/advice' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Advice</StyledLink>
                </li>
                <li>
                  <StyledLink to='/business/jobs' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Jobs</StyledLink>
                </li>
                <li>
                  <StyledLink to='/business/savings' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Savings</StyledLink>
                </li>
                <li>
                  <StyledLink to='/business/hybrid-working' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Hybrid Working</StyledLink>
                </li>
              </ul>
            </li>
            <li aria-haspopup='true'><StyledLink to='/lifestyle' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Lifestyle</StyledLink>
              <ul>
                <li>
                  <StyledLink to='/lifestyle/food-and-restaurants' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Food &amp; Restaurants</StyledLink>
                </li>
                <li>
                  <StyledLink to='/lifestyle/health' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Health</StyledLink>
                </li>
                <li>
                  <StyledLink to='/lifestyle/style-beauty' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Style &amp; Beauty</StyledLink>
                </li>
                <li>
                  <StyledLink to='/lifestyle/family' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Relationships</StyledLink>
                </li>
                <li>
                  <StyledLink to='/lifestyle/competitions' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Competitions</StyledLink>
                </li>
                <li>
                  <StyledLink to='/lifestyle/shopping' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Shopping</StyledLink>
                </li>
              </ul>
            </li>
            <li aria-haspopup='true'>
              <StyledLink to='/entertainment' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Entertainment</StyledLink>
              <ul>
                <li>
                  <StyledLink to='/entertainment/music' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Music</StyledLink>
                </li>
                <li>
                  <StyledLink to='/entertainment/movies' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Movies</StyledLink>
                </li>
                <li>
                  <StyledLink to='/entertainment/streaming' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Streaming</StyledLink>
                </li>
                <li>
                  <StyledLink to='/entertainment/celebrity-news' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Celebrity</StyledLink>
                </li>
                <li>
                  <StyledLink to='/entertainment/events' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Events</StyledLink>
                </li>
                <li aria-haspopup='true'>
                  <StyledLink to='/entertainment/whats-on' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>What's On</StyledLink>
                  <ul>
                    <li>
                      <StyledLink to='/entertainment/whats-on/joburg' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Joburg</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/entertainment/whats-on/durban' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Durban</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/entertainment/whats-on/cape-town' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Cape Town</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/entertainment/whats-on/pretoria' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Pretoria</StyledLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <StyledLink to='/entertainment/books' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Books</StyledLink>
                </li>
                <li>
                  <StyledLink to='/entertainment/royals' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Royals</StyledLink>
                </li>
                <li>
                  <StyledLink to='/entertainment/tv' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>TV</StyledLink>
                </li>
              </ul>
            </li>
            <li aria-haspopup='true'><StyledLink to='/sport' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Sport</StyledLink>
              <ul>
                <li>
                  <StyledLink to='/tags/paralympics-2024' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Paralympics 2024</StyledLink>
                </li>
                <li>
                  <StyledLink to='/sport/soccer' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Soccer</StyledLink>
                </li>
                <li>
                  <StyledLink to='/sport/soccer/psl' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Soccer PSL</StyledLink>
                </li>
                <li>
                  <StyledLink to='/sport/rugby' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Rugby</StyledLink>
                </li>
                <li>
                  <StyledLink to='/sport/cricket' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Cricket</StyledLink>
                </li>
                <li>
                  <StyledLink to='/sport/betting/' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Betting</StyledLink>
                </li>
                <li aria-haspopup='true'><StyledLink to='/motoring' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Motoring</StyledLink>
                  <ul>
                    <li>
                      <StyledLink to='/motoring/latest-launches' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Car Launches</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/motoring/industry-news' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Industry News</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/motoring/road-tests' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Road Tests</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/motoring/motorsport' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Motorsport</StyledLink>
                    </li>
                  </ul>
                </li>
                <li aria-haspopup='true'>
                  <a>More Sport</a>
                  <ul>
                    <li>
                      <StyledLink to='/sport/athletics' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Athletics</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/sport/golf' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Golf</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/sport/tennis' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Tennis</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/sport/cycling' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Cycling</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/sport/commonwealth-games' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Commonwealth Games</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/sport/mma' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>MMA</StyledLink>
                    </li>
                    <li>
                      <StyledLink to='/sport/horse-racing' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Horse Racing</StyledLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li aria-haspopup='true'><StyledLink to='/travel' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Travel</StyledLink>
              <ul>
                <li>
                  <StyledLink to='/travel/south-africa' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>South Africa</StyledLink>
                </li>
                <li>
                  <StyledLink to='/travel/africa' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Africa</StyledLink>
                </li>
                <li>
                  <StyledLink to='/travel/world' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>World</StyledLink>
                </li>
              </ul>
            </li>
            {/* <li aria-haspopup='false'><StyledLink to='/video' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Video</StyledLink>
            </li> */}
            <li aria-haspopup='true'><StyledLink to='/newspapers' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Newspapers</StyledLink>
              <ul>
                <li aria-haspopup='true' data-left='true'>
                  <StyledLink to='/newspapers' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Gauteng</StyledLink>
                  <ul>
                    <li><StyledLink to='/the-star' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>The Star</StyledLink></li>
                    <li><StyledLink to='/pretoria-news' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Pretoria News</StyledLink></li>
                    <li><StyledLink to='/saturday-star' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Saturday Star</StyledLink></li>
                    <li><StyledLink to='/sundayindependent' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Sunday Independent</StyledLink></li>
                  </ul>
                </li>
                <li aria-haspopup='true' data-left='true'>
                  <StyledLink to='/newspapers' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Western Cape</StyledLink>
                  <ul>
                    <li><StyledLink to='/capeargus' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Cape Argus</StyledLink></li>
                    <li><StyledLink to='/weekend-argus' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Weekend Argus</StyledLink></li>
                    <li><StyledLink to='/capetimes' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Cape Times</StyledLink></li>
                  </ul>
                </li>
                <li aria-haspopup='true' data-left='true'>
                  <StyledLink to='/newspapers' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>KwaZulu Natal</StyledLink>
                  <ul>
                    <li><StyledLink to='/dailynews' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Daily News</StyledLink></li>
                    <li><StyledLink to='/mercury' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>The Mercury</StyledLink></li>
                    <li><StyledLink to='/ios' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>The Independent on Saturday</StyledLink></li>
                    <li><StyledLink to='/sunday-tribune' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Sunday Tribune</StyledLink></li>
                    <li><StyledLink to='/thepost' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>The Post</StyledLink></li>
                  </ul>
                </li>
                <li>
                  <x.a href='https://www.dailyvoice.co.za' target='_blank'>Daily Voice</x.a>
                </li>
                <li>
                  <x.a href='https://www.isolezwe.co.za' target='_blank'>Isolezwe</x.a>
                </li>
                <li>
                  <x.a href='https://www.dfa.co.za' target='_blank'>DFA</x.a>
                </li>
                <li aria-haspopup='false'><StyledLink to='/business-report' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Business Report</StyledLink></li>
                <li aria-haspopup='false'><StyledLink to='/personal-finance' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Personal Finance</StyledLink></li>
              </ul>
            </li>
            <x.li justifyContent='flex-end'>
              {process.env.RAZZLE_ENABLE_NEWSLETTER_POPUPS && !isMobile &&
                <NewsletterSubscribePopup />
              }
              {!process.env.RAZZLE_ENABLE_NEWSLETTER_POPUPS &&
                <StyledLink to='/newsletters' color='white' fontWeight='medium' py={6} px={10} bg={{ _: 'red', hover: 'grey' }} mr={5} textTransform='uppercase' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>Subscribe</StyledLink>
              }
            </x.li>
          </ul>
        </StyledNavBar>
      </x.div>
    </x.div>
  )
}

const mapStateToProps = (state) => ({ ...state.user, visitorId: getVisitorId(state), sessionId: getSessionId(state) })
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
