import { x } from '@xstyled/styled-components'
import { Tab } from './ui/Tabs'
import ImageOrNoImage from './ui/Image'
import { getImageSize } from '../../lib/articleUtils'
import NoImage from '../../../assets/images/general/no-image.png'
import WeatherWidget from './WeatherWidget'

export const TvBoxSkeleton = () => (
  <>
    <x.div display='flex' flexDirection='column' position='relative' className='divider-right' p={{ sm: '0 0 20px 0', lg: '0' }} borderWidth={{ sm: '0 0 1px 0', lg: '0' }} borderStyle='solid' borderColor='divider'>
      <x.article pb={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
        <ImageOrNoImage width={getImageSize('hero')} shape='16x9' mb={10} noImage={NoImage} />
        <x.div h={7} mb={5} bg='#F0F0F0' />
        <x.div h={7} mb={5} bg='#F0F0F0' />
        <x.div w='33%' h={8} bg='#F0F0F0' />
        <x.div h={3} bg='#F0F0F0' my={8} />
        <x.div h={5} mb={3} bg='#F0F0F0' />
        <x.div h={5} bg='#F0F0F0' />
      </x.article>
      <x.article pt={20} display='flex' spaceX={{ sm: '0', xl: '10px' }} flexDirection={{ sm: 'column-reverse', xl: 'row' }}>
        <x.div flex='1 1 0'>
          <x.div h={5} mb={5} bg='#F0F0F0' />
          <x.div h={5} mb={5} bg='#F0F0F0' />
          <x.div w='33%' h={5} bg='#F0F0F0' />
          <x.div my={8} h={3} bg='#F0F0F0' />
          <x.div h={4} bg='#F0F0F0' />
        </x.div>
        <x.div mb={{ sm: '10', xl: '0' }}>
          <ImageOrNoImage width={getImageSize('primary')} shape='16x9' noImage={NoImage} />
        </x.div>
      </x.article>
    </x.div>
    <x.div position='relative' p={{ sm: '20px 0', lg: '0' }} className='divider-right' borderWidth='0'>
      <x.article pb={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' display='flex' spaceX={10}>
        <x.div flex='1 1 0'>
          <x.div h={5} mb={5} bg='#F0F0F0' />
          <x.div w='33%' h={5} bg='#F0F0F0' />
          <x.div my={8} h={3} bg='#F0F0F0' />
        </x.div>
        <x.div>
          <ImageOrNoImage width={getImageSize('normal')} shape='square' noImage={NoImage} />
        </x.div>
      </x.article>
      <x.article py={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' display='flex' spaceX={10}>
        <x.div flex='1 1 0'>
          <x.div h={5} mb={5} bg='#F0F0F0' />
          <x.div w='33%' h={5} bg='#F0F0F0' />
          <x.div my={8} h={3} bg='#F0F0F0' />
        </x.div>
        <x.div>
          <ImageOrNoImage width={getImageSize('normal')} shape='square' noImage={NoImage} />
        </x.div>
      </x.article>
      <x.article py={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
        <x.div h={5} mb={5} bg='#F0F0F0' />
        <x.div w='33%' h={5} bg='#F0F0F0' />
        <x.div my={8} h={3} bg='#F0F0F0' />
      </x.article>
      <x.article py={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
        <x.div h={5} mb={5} bg='#F0F0F0' />
        <x.div w='33%' h={5} bg='#F0F0F0' />
        <x.div my={8} h={3} bg='#F0F0F0' />
      </x.article>
      <x.article py={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
        <x.div h={5} mb={5} bg='#F0F0F0' />
        <x.div w='33%' h={5} bg='#F0F0F0' />
        <x.div my={8} h={3} bg='#F0F0F0' />
      </x.article>
      <x.article pt={20}>
        <x.div h={5} mb={5} bg='#F0F0F0' />
        <x.div w='33%' h={5} bg='#F0F0F0' />
        <x.div my={8} h={3} bg='#F0F0F0' />
      </x.article>
    </x.div>
  </>
)

export const MostReadArticlesSkeleton = (props) => (
  Array(5).fill().map((v, i) =>
    <x.article key={i} pb={{ _: '20px', lastType: '0' }} mb={{ _: '20px', lastType: '0' }} borderWidth={{ _: '0 0 1px 0', lastType: '0' }} borderStyle='solid' borderColor='divider'>
      <x.div h={5} mb={5} bg='#F0F0F0'></x.div>
      <x.div w='33%' h={5} bg='#F0F0F0'></x.div>
    </x.article>
  )
)

export const MostReadWidgetSkeleton = () => {
  return (
    <>
      <Tab.List ariaLabel='Tabs' bg='ltGrey' px={15} py={8} mb={20} display='flex' justifyContent='space-between' flexWrap='nowrap'>
        <Tab.Tab id='most-read'>
          Most Read
        </Tab.Tab>
        <Tab.Tab id='editors-choice'>
          Editor's Choice
        </Tab.Tab>
      </Tab.List>
      <Tab.Panel id='most-read' pb={20} mb={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
        <MostReadArticlesSkeleton />
      </Tab.Panel>
      <Tab.Panel id='editors-choice' pb={20} mb={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
        <div />
      </Tab.Panel>
    </>
  )
}

export const HomepageSkeleton = () => {
  return (
    <>
      <x.div w='100%' maxWidth={1220} mx='auto' display='grid' gridTemplateColumns={{ sm: '100%', lg: 'minmax(min-content, 560px) minmax(min-content, 282px) 300px' }} gap={{ lg: '40' }} mt={50} mb={30}>
        <TvBoxSkeleton />
        <x.aside>
          <MostReadWidgetSkeleton />
          <x.div textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column' p={{ sm: '20px', lg: '0' }}>
            <x.div w={300} h={250} bg='#D3D3D340' />
          </x.div>
        </x.aside>
      </x.div>
      {/* <TrendsBar />
      <x.div my={50} mx={-20} textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
        <x.div w={970} h={250} bg='#D3D3D340' />
      </x.div>
      <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
        <SixArticleDeckSkeleton />
        <ArticleDeckSkeleton />
        <ThreeArticleDeckSkeleton />
      </x.div> */}
    </>
  )
}

export const DefaultSectionPageSkeleton = () => {
  return (
    <>
      <x.div w='100%' maxWidth={1220} mx='auto' display='grid' gridTemplateColumns={{ sm: '100%', lg: 'minmax(min-content, 560px) minmax(min-content, 282px) 300px' }} gap={{ lg: '40' }} mt={50} mb={30}>
        <TvBoxSkeleton />
        <x.aside>
          <MostReadWidgetSkeleton />
          <x.div textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column' p={{ sm: '20px', lg: '0' }}>
            <x.div w={300} h={250} bg='#D3D3D340' />
          </x.div>
        </x.aside>
      </x.div>
      {/* <TrendsBar />
      <x.div my={50} mx={-20} textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
        <x.div w={970} h={250} bg='#D3D3D340' />
      </x.div>
      <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
        <SixArticleDeckSkeleton />
        <ArticleDeckSkeleton />
        <ThreeArticleDeckSkeleton />
      </x.div> */}
    </>
  )
}

export const DefaultSectionPageSkeletonWrapper = () => (
  <x.main mt={{ sm: '96px', lg: '172px' }} px={20}>
    <x.div h={7}></x.div>
    <DefaultSectionPageSkeleton />
  </x.main>
)

export const ArticleDeckSkeleton = () => {
  return (
    <x.div display={{ sm: '', lg: 'grid' }} animation='pulse' gridTemplateColumns={{ sm: '', lg: 'repeat(4, calc((100% - 120px) / 4))' }} gap={{ lg: '40' }}>
      {Array(4).fill().map((v, i) =>
        <x.article key={i} position='relative' className='divider-right' p={{ sm: '0 0 20px 0', lg: '0', lastType: '0' }} borderWidth={{ sm: '0 0 1px 0', lg: '0', lastType: '0' }} borderStyle='solid' borderColor='divider' mb={{ sm: '20px', lg: '0', last: '0' }}>
          <ImageOrNoImage width={getImageSize('slider')} shape='16x9' noImage={NoImage} />
          <x.div>
            <x.div h={7} mb={5} bg='#F0F0F0' />
            <x.div h={7} mb={5} bg='#F0F0F0' />
            <x.div w='33%' h={7} bg='#F0F0F0' />
            <x.div my={8} h={3} bg='#F0F0F0' />
          </x.div>
        </x.article>
      )}
    </x.div>
  )
}

export const ThreeArticleDeckSkeleton = () => {
  return (
    <x.div display={{ sm: '', lg: 'grid' }} animation='pulse' gridTemplateColumns={{ sm: '', lg: 'repeat(4, calc((100% - 120px) / 4))' }} gap={{ lg: '40' }}>
      {Array(4).fill().map((v, i) =>
        <x.article key={i} position='relative' className='divider-right' p={{ sm: '0 0 20px 0', lg: '0', lastType: '0' }} borderWidth={{ sm: '0 0 1px 0', lg: '0', lastType: '0' }} borderStyle='solid' borderColor='divider' mb={{ sm: '20px', lg: '0', last: '0' }}>
          <ImageOrNoImage width={getImageSize('slider')} shape='16x9' noImage={NoImage} />
          <x.div>
            <x.div h={7} mb={5} bg='#F0F0F0' />
            <x.div h={7} mb={5} bg='#F0F0F0' />
            <x.div w='33%' h={7} bg='#F0F0F0' />
            <x.div my={8} h={3} bg='#F0F0F0' />
          </x.div>
        </x.article>
      )}
    </x.div>
  )
}

export const ArticleFourRowSkeleton = () => {
  return (
    <x.div display={{ sm: '', lg: 'grid' }} gridTemplateColumns={{ sm: '', lg: 'repeat(4, calc((100% - 120px) / 4))' }} gap={{ lg: '40' }} mb={50}>
      {Array(4).fill().map((v, i) =>
        <x.article key={i} position='relative' className='divider-right' p={{ sm: '0 0 20px 0', lg: '0', lastType: '0' }} borderWidth={{ sm: '0 0 1px 0', lg: '0', lastType: '0' }} borderStyle='solid' borderColor='divider' mb={{ sm: '20px', lg: '0', last: '0' }}>
          <x.div display='flex' spaceY={10} flexDirection='column'>
            <x.div>
              <ImageOrNoImage w='100%' width={getImageSize('slider')} shape='16x9' noImage={NoImage} />
            </x.div>
            <x.div>
              <x.div h={7} mb={5} bg='#F0F0F0' />
              <x.div h={7} mb={5} bg='#F0F0F0' />
              <x.div w='33%' h={7} bg='#F0F0F0' />
              <x.div my={8} h={3} bg='#F0F0F0' />
            </x.div>
          </x.div>
        </x.article>
      )}
    </x.div>
  )
}

export const SixArticleDeckSkeleton = (props) => {
  return (
    <x.div display={{ sm: '', lg: 'grid' }} animation='pulse' gridTemplateColumns={{ sm: '', lg: 'repeat(4, calc((100% - 120px) / 4))' }} gap={{ lg: '40' }}>
      {Array(4).fill().map((v, i) =>
        <x.article key={i} position='relative' className='divider-right' p={{ sm: '0 0 20px 0', lg: '0', lastType: '0' }} borderWidth={{ sm: '0 0 1px 0', lg: '0', lastType: '0' }} borderStyle='solid' borderColor='divider' mb={{ sm: '20px', lg: '0', last: '0' }}>
          <ImageOrNoImage width={getImageSize('slider')} shape='16x9' noImage={NoImage} />
          <x.div>
            <x.div h={7} mb={5} bg='#F0F0F0' />
            <x.div h={7} mb={5} bg='#F0F0F0' />
            <x.div w='33%' h={7} bg='#F0F0F0' />
            <x.div my={8} h={3} bg='#F0F0F0' />
          </x.div>
        </x.article>
      )}
    </x.div>
  )
}

export const TvBoxSectionPageSkeleton = () => {
  return (
    <>
      <x.div w='100%' maxWidth={1220} mx='auto' display='grid' gridTemplateColumns={{ sm: '100%', lg: 'minmax(min-content, 560px) minmax(min-content, 282px) 300px' }} gap={{ lg: '40' }} mt={50} mb={30}>
        <TvBoxSkeleton />
        <x.aside>
          <MostReadWidgetSkeleton />
          <x.div textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column' p={{ sm: '20px', lg: '0' }}>
            <x.div w={300} h={250} bg='#D3D3D340' />
          </x.div>
        </x.aside>
      </x.div>
      {/* <TrendsBar />
      <x.div my={50} mx={-20} textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
        <x.div w={970} h={250} bg='#D3D3D340' />
      </x.div>
      <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
        <SixArticleDeckSkeleton />
        <ArticleDeckSkeleton />
        <ThreeArticleDeckSkeleton />
      </x.div> */}
    </>
  )
}

export const TvBoxSectionPageSkeletonWrapper = () => (
  <x.main mt={{ sm: '96px', lg: '172px' }} px={20}>
    <x.div h={7}></x.div>
    <TvBoxSectionPageSkeleton />
  </x.main>
)

export const WeatherPageSkeleton = () => {
  return (
    <>
      <x.div w='100%' maxWidth={1220} mx='auto' display='grid' gridTemplateColumns={{ sm: '100%', lg: 'minmax(min-content, 560px) minmax(min-content, 282px) 300px' }} gap={{ lg: '40' }} mt={50} mb={30}>
        <WeatherWidget />
      </x.div>
      <x.div py={20} my={50} mx={-20} textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
        <x.div w={970} h={250} bg='#D3D3D340' />
      </x.div>
      <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
        <SixArticleDeckSkeleton />
        <ArticleDeckSkeleton />
        <ThreeArticleDeckSkeleton />
      </x.div>
    </>
  )
}

export const TitleSectionPageSkeleton = () => {
  return (
    <>
      <x.div w='100%' maxWidth={1220} mx='auto' display='grid' gridTemplateColumns={{ sm: '100%', lg: 'minmax(min-content, 560px) minmax(min-content, 282px) 300px' }} gap={{ lg: '40' }} mt={50} mb={30}>
        <TvBoxSkeleton />
        <x.aside>
          <MostReadWidgetSkeleton />
          <x.div textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column' p={{ sm: '20px', lg: '0' }}>
            <x.div w={300} h={250} bg='#D3D3D340' />
          </x.div>
        </x.aside>
      </x.div>
      {/* <TrendsBar />
      <x.div my={50} mx={-20} textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
        <x.div w={970} h={250} bg='#D3D3D340' />
      </x.div>
      <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
        <SixArticleDeckSkeleton />
        <ArticleDeckSkeleton />
        <ThreeArticleDeckSkeleton />
      </x.div> */}
    </>
  )
}

export const AuthorPageSkeleton = () => {
  return (
    <>
      <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
        <x.div w='100%' maxWidth={1220} mx='auto' my={50} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
          <x.div h={7} mb={5} pb={20} bg='#F0F0F0' />
        </x.div>
        <FollowConceptSkeleton />
        <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
          <ArticleDeckSkeleton />
        </x.div>
        <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
          <ArticleDeckSkeleton />
        </x.div>
      </x.div>
    </>
  )
}

export const FollowConceptSkeleton = (props) => {
  return (
    <x.div h={7} mb={5} pb={20} bg='#F0F0F0'></x.div>
  )
}

export const BlankPageSkeleton = () => {
  return (
    <x.div maxWidth={1220} w='100%' mx='auto' h={1200} mt={50}>
    </x.div>
  )
}

export const BasicPageSkeleton = () => {
  return (
    <>
      <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
        <x.div w='100%' maxWidth={1220} mx='auto' my={50} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
          <x.div h={7} mb={5} pb={20} bg='#F0F0F0'></x.div>
        </x.div>
        <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
          <ArticleDeckSkeleton />
        </x.div>
        <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
          <ArticleDeckSkeleton />
        </x.div>
      </x.div>
    </>
  )
}

export const TrendsPageSkeleton = () => (
  <>
    <x.main px={{ sm: '20px', wAdD: '200px' }} mt={{ sm: '96px', lg: '173px'}} w='100%' maxWidth={{ sm: '1260px', wAdD: '1660px' }} mx='auto' mb={50}>
      <x.div position='relative' w='100%' maxWidth={{ sm: '1260px', wAdD: '1660px' }} mx='auto'>
        <x.div w='100%' maxWidth={{ sm: '1260px', wAdD: '1660px' }}  pb={10} my={50} mx='auto' borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
          <x.h1 text='h3' mr={{ sm: '0', lg: '50px' }} mb={{ sm: '10', lg: '0' }} w={{ sm: '100%', lg: 'auto' }}>Trending on IOL</x.h1>
        </x.div>
        <x.div position='relative' w='100%' maxWidth={{ sm: '1260px', wAdD: '1660px' }} mx='auto'>
          <x.h3 text='h4' borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' pb={10} mb={20}>
            <x.div h={7} mb={5} w='20%' bg='#F0F0F0' />
          </x.h3>
        </x.div>
        <ArticleFourRowSkeleton />
      </x.div>
    </x.main>
  </>
)

export const AuthorSkeleton = () => {
  return (
    <x.div display={{ sm: 'unset', lg: 'grid' }} gridTemplateColumns={{ sm: 'unset', lg: '127px auto' }}>
      <x.div w={{ sm: '50px', lg: '127px' }} h={{ sm: '50px', lg: '127px' }} bg='#F0F0F0' borderRadius='100%' />
      <x.div ml={20}>
        <x.p text='meta'>Written by</x.p>
        <x.div h={7} mb={5} bg='#F0F0F0'></x.div>
        <x.div h={5} mb={5} bg='#F0F0F0'></x.div>
        <x.div h={5} mb={5} bg='#F0F0F0'></x.div>
        <x.div w='33%' h={5} bg='#F0F0F0'></x.div>
        <x.div display='flex' spaceX={10} mt={10} className='author-follow'>
          <x.div w={10} h={10} bg='#F0F0F0' borderRadius='full' />
          <x.div w={10} h={10} bg='#F0F0F0' borderRadius='full' />
          <x.div w={10} h={10} bg='#F0F0F0' borderRadius='full' />
          <x.div w={10} h={10} bg='#F0F0F0' borderRadius='full' />
        </x.div>
      </x.div>
    </x.div>
  )
}

export const ArticlePageSkeleton = () => (
  <x.article className='main-article' w='100%' maxWidth='1500px' m='auto'>
    <x.div display='grid' gridTemplateColumns={{ sm: '100%', xl: '300px auto 300px' }} gap={{ sm: '0', article: '50' }} alignItems='center'>
      <x.aside display={{ sm: 'none', xl: 'block' }} visibility={{ sm: 'hidden', xl: 'visible' }}>
        <AuthorSkeleton />
      </x.aside>
      <x.div w='100%' maxWidth='800px' m='0 auto'>
        <x.div w='100%' mb={10} mt={{ sm: '5px', lg: '22px' }} h={14} bg='ltGrey' />
        <x.div w='100%' mb={{ sm: '15px', lg: '30px' }} h={24} bg='ltGrey' />
        <ImageOrNoImage width={getImageSize('featured')} shape='16x9' image={false} noImage={NoImage} />
        <x.div w='100%' maxWidth='550px' m='auto'>
          <x.div text='small' my={10}><em><x.div bg='ltGrey' h={4} w='100%' borderRadius /></em></x.div>
          <x.div h={3} mb={5} bg='#F0F0F0'></x.div>
        </x.div>
      </x.div>
      <x.aside w='100%' maxWidth='550px' m='auto' mb={30} display={{ sm: 'block', article: 'none' }} visibility={{ sm: 'visible', article: 'hidden' }}>
        <AuthorSkeleton />
      </x.aside>
      <x.div textAlign='center' justifyContent='center' alignItems='center' flexDirection='column' mb={77} display={{ sm: 'none', article: 'flex' }} visibility={{ sm: 'hidden', article: 'visible' }}>
        <x.div bg='ltGrey' w={300} h={250} />
      </x.div>
    </x.div>
  </x.article>
)

export const ArticlePageSkeletonWrapper = () => (
  <x.main mt={{ sm: '96px', lg: '172px' }} px={20}>
    <x.div h={7}></x.div>
    <ArticlePageSkeleton />
  </x.main>
)
