import React, { memo, useEffect, useRef, useState, useCallback } from 'react'
import styled, { x, th, css, system } from '@xstyled/styled-components'
import MyLink from '../components/ui/NavLink'
import SearchBar from './MobileSearchBar'

import StyledLink from '../components/ui/Link'
import { getSectionLabel } from 'ion-sections'
import logo from '../../../assets/images/general/iol.svg'
import backArrow from '../../../assets/images/icons/arrow-left-ltgrey.svg'
import searchIco from '../../../assets/images/icons/search-ico.svg'

const __HeaderLink = styled(MyLink)(
  ({ activeColor }) => css`
${system}
&.active {
  color: ${activeColor || '#B41400'};
  &::after {
    content: '';
    position: absolute;
    background: #B41400;
    bottom: -10px;
    left: 0;
    height: 3px;
    width: 100%;
  }
}
`
)

const _HeaderLink = ({ to, isActive, children, visitorId, sessionId, section, election }) => {
  if (election) {
    return (<__HeaderLink to={to} isActive={isActive} textTransform='uppercase' text='small' mr={{ _: '20px', lastType: '0' }} px={3} py={3} bg={{ _: 'red', hover: 'white' }} color={{ _: 'white', hover: 'grey' }} activeColor='white' position='relative' visitorId={visitorId} sessionId={sessionId} section={section} source='Mobile Header'>{children}</__HeaderLink>)
  } else {
    return <__HeaderLink to={to} isActive={isActive} textTransform='uppercase' text='small' mr={{ _: '20px', lastType: '0' }} color={{ _: 'grey', hover: 'red' }} position='relative' visitorId={visitorId} sessionId={sessionId} section={section} source='Mobile Header'>{children}</__HeaderLink>
  }
}

const _MobileHeaderElements = ({ section, mbElementsRef, clickSource, visitorId, sessionId }) => {
  const primarySection = section.split('/')[0]
  const secondarySection = section.split('/')[1] || section.split('/')[0]
  const navRef = useRef()
  useEffect(() => {
    setTimeout(function () {
      if (navRef.current) {
        navRef.current.scrollIntoView()
      }
    })
  }, [section])
  const HeaderLink = ({ to, children, visitorId, sessionId, section, election }) => {
    if (election) {
      return (<_HeaderLink to={to} election visitorId={visitorId} sessionId={sessionId} section={section} isActive={(match, location) => {
        if (match) {
          if (match.isExact) {
            return true
          }
          return (match.url === `/${primarySection}/${secondarySection}`)
        }
        return false
      }}>{children}</_HeaderLink>)
    } else {
      return (<_HeaderLink to={to} visitorId={visitorId} sessionId={sessionId} section={section} isActive={(match, location) => {
        if (match) {
          if (match.isExact) {
            return true
          }
          return (match.url === `/${primarySection}/${secondarySection}`)
        }
        return false
      }}>{children}</_HeaderLink>)
    }
  }

  switch (primarySection) {
    case 'news':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/news' visitorId={visitorId} sessionId={sessionId} section={section}>News</HeaderLink>
          <HeaderLink to='/news/south-africa' visitorId={visitorId} sessionId={sessionId} section={section}>South Africa</HeaderLink>
          <HeaderLink to='/news/politics' visitorId={visitorId} sessionId={sessionId} section={section}>Politics</HeaderLink>
          <HeaderLink to='/news/crime-and-courts' visitorId={visitorId} sessionId={sessionId} section={section}>Crime &amp; Courts</HeaderLink>
          <HeaderLink to='/news/world' visitorId={visitorId} sessionId={sessionId} section={section}>World</HeaderLink>
          <HeaderLink to='/news/africa' visitorId={visitorId} sessionId={sessionId} section={section}>Africa</HeaderLink>
          <HeaderLink to='/news/education' visitorId={visitorId} sessionId={sessionId} section={section}>Education</HeaderLink>
          <HeaderLink to='/news/environment' visitorId={visitorId} sessionId={sessionId} section={section}>Environment</HeaderLink>
          <HeaderLink to='/news/weather' visitorId={visitorId} sessionId={sessionId} section={section}>Weather</HeaderLink>
          <HeaderLink to='/news/feel-good' visitorId={visitorId} sessionId={sessionId} section={section}>Good News</HeaderLink>
          <HeaderLink to='/news/energy' visitorId={visitorId} sessionId={sessionId} section={section}>Energy</HeaderLink>
        </x.div>
      )
    case 'lifestyle':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/lifestyle' visitorId={visitorId} sessionId={sessionId} section={section}>Lifestyle</HeaderLink>
          <HeaderLink to='/lifestyle/love-sex' visitorId={visitorId} sessionId={sessionId} section={section}>Love &amp; Sex</HeaderLink>
          <HeaderLink to='/lifestyle/family' visitorId={visitorId} sessionId={sessionId} section={section}>Family</HeaderLink>
          <HeaderLink to='/lifestyle/health' visitorId={visitorId} sessionId={sessionId} section={section}>Health</HeaderLink>
          <HeaderLink to='/lifestyle/food-and-restaurants' visitorId={visitorId} sessionId={sessionId} section={section}>Food &amp; Restaurants</HeaderLink>
          <HeaderLink to='/lifestyle/style-beauty' visitorId={visitorId} sessionId={sessionId} section={section}>Style &amp; Beauty</HeaderLink>
          <HeaderLink to='/lifestyle/competitions' visitorId={visitorId} sessionId={sessionId} section={section}>Competitions</HeaderLink>
          <HeaderLink to='/lifestyle/shopping' visitorId={visitorId} sessionId={sessionId} section={section}>Shopping</HeaderLink>
        </x.div>
      )
    case 'entertainment':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/entertainment' visitorId={visitorId} sessionId={sessionId} section={section}>Entertainment</HeaderLink>
          <HeaderLink to='/entertainment/tv' visitorId={visitorId} sessionId={sessionId} section={section}>TV</HeaderLink>
          <HeaderLink to='/entertainment/music' visitorId={visitorId} sessionId={sessionId} section={section}>Music</HeaderLink>
          <HeaderLink to='/entertainment/movies' visitorId={visitorId} sessionId={sessionId} section={section}>Movies</HeaderLink>
          <HeaderLink to='/entertainment/streaming' visitorId={visitorId} sessionId={sessionId} section={section}>Streaming</HeaderLink>
          <HeaderLink to='/entertainment/celebrity-news' visitorId={visitorId} sessionId={sessionId} section={section}>Celebrity</HeaderLink>
          <HeaderLink to='/entertainment/events' visitorId={visitorId} sessionId={sessionId} section={section}>Events</HeaderLink>
          <HeaderLink to='/entertainment/whats-on' visitorId={visitorId} sessionId={sessionId} section={section}>Whats On</HeaderLink>
          <HeaderLink to='/entertainment/books' visitorId={visitorId} sessionId={sessionId} section={section}>Books</HeaderLink>
          <HeaderLink to='/entertainment/royals' visitorId={visitorId} sessionId={sessionId} section={section}>Royals</HeaderLink>
        </x.div>
      )
    case 'travel':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/travel' visitorId={visitorId} sessionId={sessionId} section={section}>Travel</HeaderLink>
          <HeaderLink to='/travel/africa' visitorId={visitorId} sessionId={sessionId} section={section}>Africa</HeaderLink>
          <HeaderLink to='/travel/south-africa' visitorId={visitorId} sessionId={sessionId} section={section}>South Africa</HeaderLink>
          <HeaderLink to='/travel/world' visitorId={visitorId} sessionId={sessionId} section={section}>World</HeaderLink>
        </x.div>
      )
    case 'business-report':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/business-report' visitorId={visitorId} sessionId={sessionId} section={section}>Business Report</HeaderLink>
          <HeaderLink to='/business-report/companies' visitorId={visitorId} sessionId={sessionId} section={section}>Companies</HeaderLink>
          <HeaderLink to='/business-report/economy' visitorId={visitorId} sessionId={sessionId} section={section}>Economy</HeaderLink>
          <HeaderLink to='/business-report/energy' visitorId={visitorId} sessionId={sessionId} section={section}>Energy</HeaderLink>
          <HeaderLink to='/business-report/markets' visitorId={visitorId} sessionId={sessionId} section={section}>Markets</HeaderLink>
          <HeaderLink to='/business-report/entrepreneurs' visitorId={visitorId} sessionId={sessionId} section={section}>Entrepreneurs</HeaderLink>
          <HeaderLink to='/business/jobs' visitorId={visitorId} sessionId={sessionId} section={section}>Jobs</HeaderLink>
          <HeaderLink to='/business-report/international' visitorId={visitorId} sessionId={sessionId} section={section}>International</HeaderLink>
          <HeaderLink to='/business-report/budget' visitorId={visitorId} sessionId={sessionId} section={section}>Budget</HeaderLink>
          <HeaderLink to='/business-report/ending-poverty-in-china' visitorId={visitorId} sessionId={sessionId} section={section}>Window on China</HeaderLink>
        </x.div>
      )
    case 'personal-finance':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/personal-finance' visitorId={visitorId} sessionId={sessionId} section={section}>Personal Finance</HeaderLink>
          <HeaderLink to='/personal-finance/my-money' visitorId={visitorId} sessionId={sessionId} section={section}>My Money</HeaderLink>
          <HeaderLink to='/personal-finance/debt' visitorId={visitorId} sessionId={sessionId} section={section}>Debt</HeaderLink>
          <HeaderLink to='/personal-finance/financial-planning' visitorId={visitorId} sessionId={sessionId} section={section}>Financial Planning</HeaderLink>
          <HeaderLink to='/personal-finance/medical-cover' visitorId={visitorId} sessionId={sessionId} section={section}>Medical Cover</HeaderLink>
          <HeaderLink to='/personal-finance/investments' visitorId={visitorId} sessionId={sessionId} section={section}>Investments</HeaderLink>
          <HeaderLink to='/personal-finance/insurance' visitorId={visitorId} sessionId={sessionId} section={section}>Insurance</HeaderLink>
          <HeaderLink to='/personal-finance/retirement' visitorId={visitorId} sessionId={sessionId} section={section}>Retirement</HeaderLink>
          <HeaderLink to='/personal-finance/tax' visitorId={visitorId} sessionId={sessionId} section={section}>Tax</HeaderLink>
        </x.div>
      )
    case 'property':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/property' visitorId={visitorId} sessionId={sessionId} section={section}>Property</HeaderLink>
          <HeaderLink to='/property/commercial' visitorId={visitorId} sessionId={sessionId} section={section}>Commercial</HeaderLink>
          <HeaderLink to='/property/residential' visitorId={visitorId} sessionId={sessionId} section={section}>Residential</HeaderLink>
          <HeaderLink to='/lifestyle/home-garden' visitorId={visitorId} sessionId={sessionId} section={section}>Home &amp; Garden</HeaderLink>
          <HeaderLink to='/lifestyle/green-living' visitorId={visitorId} sessionId={sessionId} section={section}>Simply Green</HeaderLink>
        </x.div>
      )
    case 'technology':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/technology' visitorId={visitorId} sessionId={sessionId} section={section}>Technology</HeaderLink>
          <HeaderLink to='/technology/mobile-apps' visitorId={visitorId} sessionId={sessionId} section={section}>Mobile Apps</HeaderLink>
          <HeaderLink to='/technology/gadgets' visitorId={visitorId} sessionId={sessionId} section={section}>Gadgets</HeaderLink>
          <HeaderLink to='/technology/fintech' visitorId={visitorId} sessionId={sessionId} section={section}>Fintech</HeaderLink>
          <HeaderLink to='/technology/gaming' visitorId={visitorId} sessionId={sessionId} section={section}>Gaming</HeaderLink>
          <HeaderLink to='/technology/techsperts' visitorId={visitorId} sessionId={sessionId} section={section}>Techsperts</HeaderLink>
          <HeaderLink to='/technology/big-tech' visitorId={visitorId} sessionId={sessionId} section={section}>Big Tech</HeaderLink>
          <HeaderLink to='/technology/e-sports' visitorId={visitorId} sessionId={sessionId} section={section}>e-Sports</HeaderLink>
          <HeaderLink to='/technology/shopping' visitorId={visitorId} sessionId={sessionId} section={section}>Shopping</HeaderLink>
        </x.div>
      )
    case 'sport':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/sport' visitorId={visitorId} sessionId={sessionId} section={section}>Sport</HeaderLink>
          <HeaderLink to='/tags/paralympics-2024' visitorId={visitorId} sessionId={sessionId} section={section}>Paralympics 2024</HeaderLink>
          <HeaderLink to='/sport/athletics' visitorId={visitorId} sessionId={sessionId} section={section}>Athletics</HeaderLink>
          <HeaderLink to='/sport/boxing' visitorId={visitorId} sessionId={sessionId} section={section}>Boxing</HeaderLink>
          <HeaderLink to='/sport/mma' visitorId={visitorId} sessionId={sessionId} section={section}>MMA</HeaderLink>
          <HeaderLink to='/sport/cycling' visitorId={visitorId} sessionId={sessionId} section={section}>Cycling</HeaderLink>
          <HeaderLink to='/sport/cricket' visitorId={visitorId} sessionId={sessionId} section={section}>Cricket</HeaderLink>
          <HeaderLink to='/sport/golf' visitorId={visitorId} sessionId={sessionId} section={section}>Golf</HeaderLink>
          <HeaderLink to='/sport/rugby' visitorId={visitorId} sessionId={sessionId} section={section}>Rugby</HeaderLink>
          <HeaderLink to='/sport/soccer' visitorId={visitorId} sessionId={sessionId} section={section}>Soccer</HeaderLink>
          <HeaderLink to='/sport/soccer/psl' visitorId={visitorId} sessionId={sessionId} section={section}>PSL</HeaderLink>
          <HeaderLink to='/sport/tennis' visitorId={visitorId} sessionId={sessionId} section={section}>Tennis</HeaderLink>
          <HeaderLink to='/sport/betting' visitorId={visitorId} sessionId={sessionId} section={section}>Betting</HeaderLink>
        </x.div>
      )
    case 'motoring':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/motoring' visitorId={visitorId} sessionId={sessionId} section={section}>Motoring</HeaderLink>
          <HeaderLink to='/motoring/latest-launches' visitorId={visitorId} sessionId={sessionId} section={section}>Car Launches</HeaderLink>
          <HeaderLink to='/motoring/road-tests' visitorId={visitorId} sessionId={sessionId} section={section}>Road Tests</HeaderLink>
          <HeaderLink to='/motoring/f1-grand-prix' visitorId={visitorId} sessionId={sessionId} section={section}>F1</HeaderLink>
          <HeaderLink to='/motoring/motorsport' visitorId={visitorId} sessionId={sessionId} section={section}>Motorsport</HeaderLink>
          <HeaderLink to='/motoring/industry-news' visitorId={visitorId} sessionId={sessionId} section={section}>Industry News</HeaderLink>
          <HeaderLink to='/motoring/custom-cars' visitorId={visitorId} sessionId={sessionId} section={section}>Custom Cars</HeaderLink>
        </x.div>
      )
    case 'opinion':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/opinion' visitorId={visitorId} sessionId={sessionId} section={section}>Opinion</HeaderLink>
          <HeaderLink to='/opinion/letters' visitorId={visitorId} sessionId={sessionId} section={section}>Letters</HeaderLink>
          <HeaderLink to='/news/politics/opinion' visitorId={visitorId} sessionId={sessionId} section={section}>Politics</HeaderLink>
          <HeaderLink to='/lifestyle/opinion' visitorId={visitorId} sessionId={sessionId} section={section}>Lifstyle</HeaderLink>
          <HeaderLink to='/business-report/opinion' visitorId={visitorId} sessionId={sessionId} section={section}>Business</HeaderLink>
          <HeaderLink to='/sport/opinion' visitorId={visitorId} sessionId={sessionId} section={section}>Sport</HeaderLink>
        </x.div>
      )
    case 'capetimes':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/capetimes' visitorId={visitorId} sessionId={sessionId} section={section}>Cape Times</HeaderLink>
          <HeaderLink to='/capetimes/news' visitorId={visitorId} sessionId={sessionId} section={section}>News</HeaderLink>
          <HeaderLink to='/capetimes/sport' visitorId={visitorId} sessionId={sessionId} section={section}>Sport</HeaderLink>
          <HeaderLink to='/capetimes/business' visitorId={visitorId} sessionId={sessionId} section={section}>Business</HeaderLink>
          <HeaderLink to='/capetimes/opinion' visitorId={visitorId} sessionId={sessionId} section={section}>Opinion</HeaderLink>
          <HeaderLink to='/capetimes/technology' visitorId={visitorId} sessionId={sessionId} section={section}>Technology</HeaderLink>
          <HeaderLink to='/capetimes/lifestyle' visitorId={visitorId} sessionId={sessionId} section={section}>Lifestyle</HeaderLink>
          <HeaderLink to='/capetimes/arts-portal' visitorId={visitorId} sessionId={sessionId} section={section}>Arts Portal</HeaderLink>
        </x.div>
      )
    case 'capeargus':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/capeargus' visitorId={visitorId} sessionId={sessionId} section={section}>Cape Argus</HeaderLink>
          <HeaderLink to='/capeargus/news' visitorId={visitorId} sessionId={sessionId} section={section}>News</HeaderLink>
          <HeaderLink to='/capeargus/opinion' visitorId={visitorId} sessionId={sessionId} section={section}>Opinion</HeaderLink>
          <HeaderLink to='/capeargus/lifestyle' visitorId={visitorId} sessionId={sessionId} section={section}>Lifestyle</HeaderLink>
          <HeaderLink to='/capeargus/sport' visitorId={visitorId} sessionId={sessionId} section={section}>Sport</HeaderLink>
          <HeaderLink to='/capeargus/leadsa' visitorId={visitorId} sessionId={sessionId} section={section}>Lead SA</HeaderLink>
          <HeaderLink to='/capeargus/motoring' visitorId={visitorId} sessionId={sessionId} section={section}>Motoring</HeaderLink>
          <HeaderLink to='/capeargus/cycle-tour' visitorId={visitorId} sessionId={sessionId} section={section}>Cycle Tour</HeaderLink>
          <HeaderLink to='/capeargus/the-dignity-project' visitorId={visitorId} sessionId={sessionId} section={section}>The Dignity Project</HeaderLink>
        </x.div>
      )
    case 'saturday-star':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/saturday-star' visitorId={visitorId} sessionId={sessionId} section={section}>Saturday Star</HeaderLink>
          <HeaderLink to='/saturday-star/news' visitorId={visitorId} sessionId={sessionId} section={section}>News</HeaderLink>
          <HeaderLink to='/saturday-star/sport' visitorId={visitorId} sessionId={sessionId} section={section}>Sport</HeaderLink>
          <HeaderLink to='/saturday-star/world' visitorId={visitorId} sessionId={sessionId} section={section}>World</HeaderLink>
          <HeaderLink to='/saturday-star/opinion' visitorId={visitorId} sessionId={sessionId} section={section}>Opinion</HeaderLink>
          <HeaderLink to='/saturday-star/travel' visitorId={visitorId} sessionId={sessionId} section={section}>Travel</HeaderLink>
          <HeaderLink to='/saturday-star/canvas' visitorId={visitorId} sessionId={sessionId} section={section}>Canvas</HeaderLink>
          <HeaderLink to='/saturday-star/48-hours' visitorId={visitorId} sessionId={sessionId} section={section}>48 Hours</HeaderLink>
        </x.div>
      )
    case 'the-star':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/the-star' visitorId={visitorId} sessionId={sessionId} section={section}>The Star</HeaderLink>
          <HeaderLink to='/the-star/news' visitorId={visitorId} sessionId={sessionId} section={section}>News</HeaderLink>
          <HeaderLink to='/the-star/sport' visitorId={visitorId} sessionId={sessionId} section={section}>Sport</HeaderLink>
          <HeaderLink to='/the-star/lifestyle' visitorId={visitorId} sessionId={sessionId} section={section}>Lifestyle</HeaderLink>
          <HeaderLink to='/the-star/opinion-analysis' visitorId={visitorId} sessionId={sessionId} section={section}>Opinion &amp; Analysis</HeaderLink>
          <HeaderLink to='/the-star/soweto' visitorId={visitorId} sessionId={sessionId} section={section}>Soweto</HeaderLink>
        </x.div>
      )
    case 'dailynews':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/dailynews' visitorId={visitorId} sessionId={sessionId} section={section}>Daily News</HeaderLink>
          <HeaderLink to='/dailynews/news' visitorId={visitorId} sessionId={sessionId} section={section}>News</HeaderLink>
          <HeaderLink to='/dailynews/sport' visitorId={visitorId} sessionId={sessionId} section={section}>Sport</HeaderLink>
          <HeaderLink to='/dailynews/opinion' visitorId={visitorId} sessionId={sessionId} section={section}>Opinion</HeaderLink>
          <HeaderLink to='/dailynews/lifestyle' visitorId={visitorId} sessionId={sessionId} section={section}>Lifestyle</HeaderLink>
          <HeaderLink to='/dailynews/money' visitorId={visitorId} sessionId={sessionId} section={section}>Money</HeaderLink>
          <HeaderLink to='/dailynews/consumer' visitorId={visitorId} sessionId={sessionId} section={section}>Consumer</HeaderLink>
          <HeaderLink to='/dailynews/tonight' visitorId={visitorId} sessionId={sessionId} section={section}>Tonight</HeaderLink>
          <HeaderLink to='/dailynews/features' visitorId={visitorId} sessionId={sessionId} section={section}>Features</HeaderLink>
          <HeaderLink to='/dailynews/business' visitorId={visitorId} sessionId={sessionId} section={section}>Business</HeaderLink>
          <HeaderLink to='/dailynews/motoring' visitorId={visitorId} sessionId={sessionId} section={section}>Motoring</HeaderLink>
        </x.div>
      )
    case 'weekend-argus':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/weekend-argus' visitorId={visitorId} sessionId={sessionId} section={section}>Weekend Argus</HeaderLink>
          <HeaderLink to='/weekend-argus/news' visitorId={visitorId} sessionId={sessionId} section={section}>News</HeaderLink>
          <HeaderLink to='/weekend-argus/sport' visitorId={visitorId} sessionId={sessionId} section={section}>Sport</HeaderLink>
          <HeaderLink to='/weekend-argus/lifestyle' visitorId={visitorId} sessionId={sessionId} section={section}>Lifestyle</HeaderLink>
          <HeaderLink to='/weekend-argus/entertainment' visitorId={visitorId} sessionId={sessionId} section={section}>Entertainment</HeaderLink>
          <HeaderLink to='/weekend-argus/opinion' visitorId={visitorId} sessionId={sessionId} section={section}>Opinion</HeaderLink>
        </x.div>
      )
    case 'mercury':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/mercury' visitorId={visitorId} sessionId={sessionId} section={section}>The Mercury</HeaderLink>
          <HeaderLink to='/mercury/news' visitorId={visitorId} sessionId={sessionId} section={section}>News</HeaderLink>
          <HeaderLink to='/mercury/world' visitorId={visitorId} sessionId={sessionId} section={section}>World</HeaderLink>
          <HeaderLink to='/mercury/business' visitorId={visitorId} sessionId={sessionId} section={section}>Business</HeaderLink>
          <HeaderLink to='/mercury/sport' visitorId={visitorId} sessionId={sessionId} section={section}>Sport</HeaderLink>
          <HeaderLink to='/mercury/goodlife' visitorId={visitorId} sessionId={sessionId} section={section}>Goodlife</HeaderLink>
          <HeaderLink to='/mercury/network' visitorId={visitorId} sessionId={sessionId} section={section}>Network</HeaderLink>
          <HeaderLink to='/mercury/opinion' visitorId={visitorId} sessionId={sessionId} section={section}>Opinion</HeaderLink>
          <HeaderLink to='/mercury/environment' visitorId={visitorId} sessionId={sessionId} section={section}>Environment</HeaderLink>
        </x.div>
      )
    case 'sunday-tribune':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/sunday-tribune' visitorId={visitorId} sessionId={sessionId} section={section}>Sunday Tribune</HeaderLink>
          <HeaderLink to='/sunday-tribune/news' visitorId={visitorId} sessionId={sessionId} section={section}>News</HeaderLink>
          <HeaderLink to='/sunday-tribune/sport' visitorId={visitorId} sessionId={sessionId} section={section}>Sport</HeaderLink>
          <HeaderLink to='/sunday-tribune/world' visitorId={visitorId} sessionId={sessionId} section={section}>World</HeaderLink>
          <HeaderLink to='/sunday-tribune/travel' visitorId={visitorId} sessionId={sessionId} section={section}>Travel</HeaderLink>
          <HeaderLink to='/sunday-tribune/lifestyle' visitorId={visitorId} sessionId={sessionId} section={section}>Lifestyle</HeaderLink>
          <HeaderLink to='/sunday-tribune/entertainment' visitorId={visitorId} sessionId={sessionId} section={section}>Entertainment</HeaderLink>
          <HeaderLink to='/sunday-tribune/opinion' visitorId={visitorId} sessionId={sessionId} section={section}>Opinion</HeaderLink>
        </x.div>
      )
    case 'pretoria-news':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/pretoria-news' visitorId={visitorId} sessionId={sessionId} section={section}>Pretoria News</HeaderLink>
          <HeaderLink to='/pretoria-news/news' visitorId={visitorId} sessionId={sessionId} section={section}>News</HeaderLink>
          <HeaderLink to='/pretoria-news/sport' visitorId={visitorId} sessionId={sessionId} section={section}>Sport</HeaderLink>
          <HeaderLink to='/pretoria-news/opinion' visitorId={visitorId} sessionId={sessionId} section={section}>Opinion</HeaderLink>
          <HeaderLink to='/pretoria-news/world' visitorId={visitorId} sessionId={sessionId} section={section}>World</HeaderLink>
          <HeaderLink to='/pretoria-news/multimedia' visitorId={visitorId} sessionId={sessionId} section={section}>Multimedia</HeaderLink>
          <HeaderLink to='/pretoria-news/business-report' visitorId={visitorId} sessionId={sessionId} section={section}>Business Report</HeaderLink>
          <HeaderLink to='/pretoria-news/lifestyle' visitorId={visitorId} sessionId={sessionId} section={section}>Lifestyle</HeaderLink>
        </x.div>
      )
    case 'sundayindependent':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/sundayindependent' visitorId={visitorId} sessionId={sessionId} section={section}>Sunday Independent</HeaderLink>
          <HeaderLink to='/sundayindependent/news' visitorId={visitorId} sessionId={sessionId} section={section}>News</HeaderLink>
          <HeaderLink to='/sundayindependent/lifestyle' visitorId={visitorId} sessionId={sessionId} section={section}>Lifestyle</HeaderLink>
          <HeaderLink to='/sundayindependent/analysis' visitorId={visitorId} sessionId={sessionId} section={section}>Analysis</HeaderLink>
          <HeaderLink to='/sundayindependent/international' visitorId={visitorId} sessionId={sessionId} section={section}>International</HeaderLink>
          <HeaderLink to='/sundayindependent/dispatch' visitorId={visitorId} sessionId={sessionId} section={section}>Dispatch</HeaderLink>
        </x.div>
      )
    case 'thepost':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/thepost' visitorId={visitorId} sessionId={sessionId} section={section}>The Post</HeaderLink>
          <HeaderLink to='/thepost/news' visitorId={visitorId} sessionId={sessionId} section={section}>News</HeaderLink>
          <HeaderLink to='/thepost/sport' visitorId={visitorId} sessionId={sessionId} section={section}>Sport</HeaderLink>
          <HeaderLink to='/thepost/bollywood' visitorId={visitorId} sessionId={sessionId} section={section}>Bollywood</HeaderLink>
          <HeaderLink to='/thepost/letters' visitorId={visitorId} sessionId={sessionId} section={section}>Letters</HeaderLink>
          <HeaderLink to='/thepost/opinion' visitorId={visitorId} sessionId={sessionId} section={section}>Opinion</HeaderLink>
          <HeaderLink to='/thepost/features' visitorId={visitorId} sessionId={sessionId} section={section}>Features</HeaderLink>
          <HeaderLink to='/thepost/community-news' visitorId={visitorId} sessionId={sessionId} section={section}>Community News</HeaderLink>
        </x.div>
      )
    case 'ios':
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <StyledLink to='/' textTransform='uppercase' text='small' mr={20} color={{ _: 'grey', hover: 'red' }} display='grid' gridTemplateColumns='24px auto' gap={5} alignItems='center' borderRightWidth={1} borderRightStyle='solid' borderRightColor='divider' pr={20} visitorId={visitorId} sessionId={sessionId} source={clickSource}><x.img src={backArrow} /><span>IOL</span></StyledLink>
          <HeaderLink to='/ios' visitorId={visitorId} sessionId={sessionId} section={section}>Independent on Saturday</HeaderLink>
          <HeaderLink to='/ios/news' visitorId={visitorId} sessionId={sessionId} section={section}>News</HeaderLink>
          <HeaderLink to='/ios/world' visitorId={visitorId} sessionId={sessionId} section={section}>World</HeaderLink>
          <HeaderLink to='/ios/sport' visitorId={visitorId} sessionId={sessionId} section={section}>Sport</HeaderLink>
          <HeaderLink to='/ios/opinion' visitorId={visitorId} sessionId={sessionId} section={section}>Opinion</HeaderLink>
          <HeaderLink to='/ios/arts' visitorId={visitorId} sessionId={sessionId} section={section}>Arts</HeaderLink>
          <HeaderLink to='/ios/behindthenews' visitorId={visitorId} sessionId={sessionId} section={section}>Behind The News</HeaderLink>
        </x.div>
      )
    default:
      return (
        <x.div display='flex' overflowX='scroll' whiteSpace='nowrap' px={10} py={10} ref={mbElementsRef}>
          <HeaderLink to='/news' visitorId={visitorId} sessionId={sessionId} section={section}>News</HeaderLink>
          <HeaderLink to='/opinion' visitorId={visitorId} sessionId={sessionId} section={section}>Opinion</HeaderLink>
          <HeaderLink to='/business' visitorId={visitorId} sessionId={sessionId} section={section}>Business</HeaderLink>
          <HeaderLink to='/lifestyle' visitorId={visitorId} sessionId={sessionId} section={section}>Lifestyle</HeaderLink>
          <HeaderLink to='/entertainment' visitorId={visitorId} sessionId={sessionId} section={section}>Entertainment</HeaderLink>
          <HeaderLink to='/travel' visitorId={visitorId} sessionId={sessionId} section={section}>Travel</HeaderLink>
          <HeaderLink to='/business-report' visitorId={visitorId} sessionId={sessionId} section={section}>Business Report</HeaderLink>
          <HeaderLink to='/personal-finance' visitorId={visitorId} sessionId={sessionId} section={section}>Personal Finance</HeaderLink>
          <HeaderLink to='/property' visitorId={visitorId} sessionId={sessionId} section={section}>Property</HeaderLink>
          <HeaderLink to='/technology' visitorId={visitorId} sessionId={sessionId} section={section}>Technology</HeaderLink>
          <HeaderLink to='/sport' visitorId={visitorId} sessionId={sessionId} section={section}>Sport</HeaderLink>
          <HeaderLink to='/motoring' visitorId={visitorId} sessionId={sessionId} section={section}>Motoring</HeaderLink>
          {/* <HeaderLink to='/video' visitorId={visitorId} sessionId={sessionId} section={section}>Video</HeaderLink> */}
          <HeaderLink to='/newspapers' visitorId={visitorId} sessionId={sessionId} section={section}>Newspapers</HeaderLink>
        </x.div>
      )
  }
}

const MobileHeader = (props) => {
  const [searchOpened, setSearchOpened] = useState(false)
  const searchBox = useRef(null)
  const mbElements = useRef(null)
  const clickSource = 'Mobile Header'

  useEffect(() => {
    if (searchBox.current) {
      searchBox.current.focus()
    }
  }, [searchBox.current])
  useEffect(() => {
    if (mbElements.current) {
      const selected = mbElements.current.getElementsByClassName('active')[0]
      if (selected) {
        selected.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'center' })
      }
    }
  }, [props.section, mbElements.current])

  return (
    <x.div w='100%' visibility={{ sm: props.visible ? 'visible' : 'hidden', lg: 'hidden' }} display={{ sm: props.visible ? 'flex' : 'none', lg: 'none' }} flexDirection='column' position='relative' backgroundColor='white' boxShadow='0px 0px 4px rgba(0, 0, 0, 0.25)'>
      <x.div w='100vw' display='grid' alignItems='center' position='relative' gridTemplateColumns='auto 24px 48px' px={10} mt={10}>
        <x.div display='grid' alignItems='center' gridTemplateColumns='100px auto' gap={5}>
          <StyledLink to='/' section={props.section} visitorId={props.visitorId} sessionId={props.sessionId} source={clickSource}><x.img src={logo} alt='Independent Online' minWidth={{ lg: '100%' }} h={{ sm: '30px' }} visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource} /></StyledLink>
          {props.section &&
            <StyledLink to={'/' + props.section.split('/')[0]} text='small' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}> | {props.titleLabel || getSectionLabel(props.section.split('/')[0])}</StyledLink>}
        </x.div>
        <x.a onClick={() => { setSearchOpened(!searchOpened); return false }} cursor='pointer'><x.img src={searchIco} alt='Search IOL' /></x.a>
        {!props.isNavOpen && searchOpened && <SearchBar searchOpened={searchOpened} closeHandler={setSearchOpened} searchRef={searchBox} />}
        <x.div pl={10} display='grid' justifyContent='center' alignItems='center'>
          {props.children}
        </x.div>
      </x.div>
      <MobileHeaderElements section={props.section} mbElementsRef={mbElements} visitorId={props.visitorId} sessionId={props.sessionId} clickSource={clickSource} />
    </x.div>
  )
}

export const MobileHeaderElements = memo(_MobileHeaderElements)

export default MobileHeader
