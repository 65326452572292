import React from 'react'
import { x } from '@xstyled/styled-components'
import StyledLink from '../components/ui/Link'
import logo from '../../../assets/images/general/iol-logo.svg'
import NotificationsBell from '../components/NotificationsBell'

import facebook from '../../../assets/images/social-media/facebook.svg'
import twitter from '../../../assets/images/social-media/x.svg'
import weather from '../../../assets/images/icons/weather.svg'
import search from '../../../assets/images/icons/search-input-wht-ico.svg'

const HomeHeader = (props) => {
  const clickSource = 'Site Header'
  const section = props.section || 'news'
  return (
  <x.div w='100%' zIndex={props.wallpaperActive ? '0' : '11'} maxWidth='1220px' m='auto' py={20} px={{ sm: '20px', xl: '0' }} display={{ sm: 'none', lg: 'flex' }} visibility={{ sm: 'hidden', lg: 'visible' }} justifyContent='center' alignItems='center'>
    <x.div flex={{ sm: '100%', lg: '1 1 0' }} display='flex' alignItems='center' justifyContent={{ sm: 'center', lg: 'flex-start' }}>
      <StyledLink to='/' visitorId={props.visitorId} sessionId={props.sessionId} source={clickSource} section={section}><x.img src={logo} alt='Independent Online' maxWidth='100px' /></StyledLink>
    </x.div>
    <form action='/search' method='get' onSubmit={(e) => {
      const formData = new FormData(e.target)
      if (!formData.get('q')) {
        e.preventDefault()
      }
    }}>
      <x.div flex={{ sm: '100%', lg: '1 1 0' }} display='flex' alignItems='center' justifyContent='center'>
        <x.input type='search' name='q' bg='ltGrey' p={10} text='medium' w={300} appearance='none' borderRadius={0} placeholder='Search IOL' />
        <x.button bg='grey' type='submit' w={38} h={38} display='flex' alignItems='center' justifyContent='center'><x.img src={search} w={24} /></x.button>
      </x.div>
    </form>
    <x.div flex='1 1 0' textTransform='uppercase' fontSize='small' display='flex' alignItems='flex-end' justifyContent='center' flexDirection='column' textAlign='right'>
      <x.p text='meta' fontWeight='semiBold' color='grey' mb={10}>{new Date().toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}</x.p>
      <x.div display='flex' justifyContent='flex-end' flexDirection='row'>
        <NotificationsBell visitorId={props.visitorId} sessionId={props.sessionId} section={section} />
        <x.a href='https://www.facebook.com/IOLnews' target='_blank' mr={10} title='Like us on Facebook'><img src={facebook} alt='Like us on Facebook' /></x.a>
        <x.a href='https://www.twitter.com/IOL' target='_blank' mr={10} title='Follow us on Twitter'><x.img src={twitter} alt='Follow us on Twitter' h='20px' /></x.a>
        <StyledLink to='/news/weather' mr={10} title='View weather by location' visitorId={props.visitorId} sessionId={props.sessionId} source={clickSource} section={section}><x.img src={weather} alt='View weather by location' /></StyledLink>
      </x.div>
    </x.div>
  </x.div>
)}

export default HomeHeader
